@use '@carbon/styles/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/styles/scss/config' as *;
@use '@carbon/layout';
@use "@carbon/styles/scss/theme" as *;

// @use '@carbon/react/scss/zone';
// @import '@carbon/themes/scss/themes';

.#{$prefix}--css-grid-column {
    margin-bottom: 1rem;
}

// .notification {
//     justify-items: end;
// }

.header_grid {
    align-items: center;
    margin-bottom: 1rem;
}

// .grid-column-article.cds--css-grid-column {
//     --cds-grid-margin: calc(var(--cds-grid-gutter) / 2);
//     --cds-grid-gutter: 1rem;
//     margin-right: calc(var(--cds-grid-gutter) / 4);
//     margin-left:  calc(var(--cds-grid-gutter) / 4);
//     --cds-grid-gutter-start: calc(var(--cds-grid-gutter) / 4);
//     --cds-grid-gutter-end: calc(var(--cds-grid-gutter) / 4);
// }

.cds--css-grid-column {
    // margin-right: 0px;
    --cds-grid-gutter-start: calc(var(--cds-grid-gutter) / 4);
    --cds-grid-gutter-end: calc(var(--cds-grid-gutter) / 4);
}

.header__title {
    @include type-style('fluid-heading-04', true);
}

.header__add-button {
    justify-self: end;        
}

@include breakpoint-down(md) {
    .cds--inline-notification {
        max-width: 100%;
    }
    .header__add-button {
        justify-self: unset;
        button {
            width: 100%;
            max-width: 100%;
        }
    }
    // .#{$prefix}--css-grid {
    //     --cds-grid-gutter-start: 0;
    // }
}

@include breakpoint-up(sm) {
    .cds--inline-notification {
        max-width: 100%;
    }
}


// .header__add-button  {
//     justify-self: end;
// }

// .header__add-button.cds--sm {
//     justify-self: start;
// }