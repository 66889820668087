@use '@carbon/styles/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/styles/scss/config' as *;
@use '@carbon/layout';

.tariff_info_header {
    @include type-style('heading-03', true);
    margin-bottom: layout.$spacing-05;
}

.upgrade_link {
    @include type-style('body-compact-01', true);
    margin-left: 0.5rem;
    padding-top: 0.4rem;
}

.valid_to {
    margin-left: auto;
    padding-top: 0.4rem;
}

// .#{$prefix}--progress-bar {
//     margin-bottom: layout.$spacing-05;
// }

.response_time {
    @include type-style('body-01', true);
}