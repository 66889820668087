@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/config' as *;

.create-app-modal-header {
    @include type-style('productive-heading-03', true);
    margin-bottom: 1rem;
}

.#{$prefix}--text-input__invalid-icon {
    top: 35%;
}
.arrow-right {
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 15px solid white;
    margin-left: 5px;
  }

.open_video {
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 100;
    width: 45px;
    height: 45px;
    background-color: black;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 20px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.open_video:hover {
    background-color: rgb(50, 48, 48);
}
.wrapper_modal .cds--modal-header {
    padding: 0px !important;
    margin: 0px !important;
}
.wrapper_modal .cds--modal-close {
    top: -50px !important; 
    right: -50px !important;
}
.wrapper_modal .cds--modal-container {
    overflow: unset !important;
    padding: 0px !important;
    margin: 0px !important;
    background-color: unset !important;
}
.wrapper_modal .cds--modal-content {
    padding: 0px !important;
    margin: 0px !important;
}