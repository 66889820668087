@use '@carbon/styles/scss/config' as *;
@use "@carbon/styles/scss/theme" as *;
@use '@carbon/styles/scss/type' as *;
@use "@carbon/layout" as *;

.#{$prefix}--content {
    // margin-top: 3rem;
    margin-top: $spacing-09;
    background: $background;
    height: 100%;
    overflow-y: auto;
    display: grid;
    align-items: start;
}

.terms-of-service-text {
    // font-size: 12px;
    @include type-style('legal-01', true);
    
}

// @import '@carbon/themes/scss/themes';
// @import '@carbon/themes/scss/theme';
// map.get($g10, "background")
// @include theme($theme--g10);
// background: custom-property.get-var('background');
// var(--cds-background)