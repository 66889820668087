@use '@carbon/styles/scss/type' as *;

.upgrade_info_header {
    @include type-style('fluid-heading-03', true);
    margin-bottom: 1rem;
}

.upgrade_info_header_1 {
    @include type-style('fluid-heading-03', true);
}

.productive_body_01 {
    @include type-style('body-01', true);
}

.fluid_header_04 {
    @include type-style('fluid-heading-04', true);
}
.text_bonus {
    font-size: 18px;
    font-weight: 600;
    color: #42BE65; 
}
.text_bonus_base {
    color: #A8A8A8; 
}
.bonus_info {
    padding-top: 10px;
}
.bonus_info p{
    font-size: 12px;
}
.check_price {
    font-size: 12px;
    color: #0F62FE; 
    text-decoration: underline;
    padding-top: 15px;
    cursor: pointer; 
}
.title_text {
    font-size: 16px;
    font-weight: 600;
}
.lock_block {
    display: flex;
    align-items: center;
    padding-top: 16px;
}
.lock_block span {
    color: #525252; 
    font-size: 14px;
    padding-left: 10px;
}

.flex_section {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}
.mark_blue {
    color: #0F62FE; 
    font-weight: 700;
}
.cds--modal-close:focus {
    border: none !important;
}
.cds--modal-content {
    margin-bottom: 5px !important;
}
.contact_us {
    color: #0F62FE; 
    text-decoration: underline;
    cursor: pointer; 
}