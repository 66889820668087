@use '@carbon/react/scss/breakpoint' as *;

.section_wrapper {
    margin-bottom: 12px;
}

.pricing-info-box {
    position: relative;
}

.text-widht-80 {
    width: 80%;
}

.special-offer-mark {
    position: absolute;
    right: -40px; 
    bottom: 40px;
    width: 82px;
    height: 82px; 
    background-color: #0f62fe;
    color: #fff; 
    padding-top: 5px;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    
    .text-lg {
        font-size: 20px;
    }
}




@include breakpoint-down(md) {
    .powered-by-stripe {
        flex-wrap: wrap;
    }
    .special-offer-mark {
        bottom: -40px;
        right: -30px;
    }
    .text-widht-80 {
        width: 100%;
    }
}


@include breakpoint-down(lg) {
    .stripe-payment-form-column {
        order: 2
    }

    .upgrade-account-info-column {
        order: 1
    }
}



