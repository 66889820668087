// @use './components/dashboard/dashboard-navbar';
@use './components/dashboard/dashboard-layout';
@use './pages/dashboard/main';
@use './pages/dashboard/upgrade-account';

@use './components/dashboard/main/article-tile';
@use './components/dashboard/main/tariff-info';

@use './components/dashboard/main/add-app-modal';
@use './components/dashboard/main/app-table';

@use './components/dashboard/billing-and-usage/billing-information';
@use './components/dashboard/billing-and-usage/usage-summary-chart';

@use './components/dashboard/upgrade-account/upgrade-account-info';

@use './components/dashboard/account/contact-information';
@use './components/dashboard/account/change-password';

body {
    position: fixed;
    width: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

.hide-icon .cds--progress-bar__status-icon {
    display: none !important;
}
