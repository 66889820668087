@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/config' as *;

.fluid_heading_03_font {
    @include type-style('fluid-heading-03', true);
}

.full_width_hr {
    width: calc(100% + 2rem);
    color: #8d8d8d;
    height: 2px;
    background-color: #8d8d8d;
    border-width: 0px;
    margin-left: -1rem;
    margin-top: 0px;
}

.body_compact_01_font {
    @include type-style('body-compact-01');
}

.body_01_font {
    @include type-style('body-01');
}